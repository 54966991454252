import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";

enum Color {
    Primary = "#00C599",
    PrimaryDark = "#00B38B",
    Secondary = "#038A8A",
    Light = "#fff",
    Lighter = "#f4f9f7",
    Lightest = "#F2F4F9",
    Dark = "#012E40",
    Grey = "#0A242E",
    LightGrey = "#334C56",
    LighterGrey = "#71858D",
    MoreLighterGrey = "#ABB9BF",
    Cream = "#F7ECDE",
    LightCream = "#FFF8F0",
    Info = "#D7EAFF",
    Warning = "#FFEED6",
    Danger = "#FFE0DE",
    Success = "#DFF6E4",
    MoreSuccess = "#9EE4AF"
}

enum Radius {
    None = "0",
    Rounded = "50vmax"
}

enum Size {
    XSmall = ".25rem",
    Small = ".5rem",
    SmallMedium = ".75rem",
    Medium = "1rem",
    Large = "1.5rem",
    XLarge = "2rem",
    XXLarge = "3rem"
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum Font {
    Default = "'Inter', sans-serif",
    Nordique = "'Nordique Pro', sans-serif"
}

export const LeviiaTheme = definePreset(Aura, {
    options: {
        darkModeSelector: ".mon-super-dark-mode"
    },
    semantic: {
        colorScheme: {
            light: {
                primary: {
                    color: Color.Primary,
                    hoverColor: Color.PrimaryDark,
                    activeColor: Color.Primary
                },
                root: {
                    color: Color.Dark
                }
            },
            dark: null
        }
    },
    components: {
        // -------------------------------------------------------------------------------------------------------------
        // ---[ BUTTON
        // -------------------------------------------------------------------------------------------------------------
        button: {
            colorScheme: {
                primary: {
                    color: Color.Light,
                    backgroundColor: Color.Primary,
                    hover: "red",

                    fontSize: "5rem",
                    size: "5rem"
                },
                dark: null
            },
            label: {
                // fontWeight: "bold"
                dark: null
            },
            sm: {
                fontSize: ".8rem",
                padding: {
                    y: Size.SmallMedium,
                    x: Size.Large
                }
            },
            borderRadius: Radius.Rounded
        },
        // -------------------------------------------------------------------------------------------------------------
        // ---[ DATATABLE
        // -------------------------------------------------------------------------------------------------------------
        datatable: {
            headerCell: {
                background: Color.Cream
            }
        },
        // -------------------------------------------------------------------------------------------------------------
        // ---[ INPUTTEXT
        // -------------------------------------------------------------------------------------------------------------
        inputtext: {
            colorScheme: {
                dark: null
            }
        }
    }
});
