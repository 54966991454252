<script lang="ts" setup>
import { usePreset } from "@primevue/themes";

import { LeviiaTheme } from "~/theme/theme";

function updatePreset (): void {
    usePreset(LeviiaTheme);
}

const isLocal = ref<boolean>(false);

onMounted(() => {
    isLocal.value = document.location.hostname === "localhost";
});
</script>

<template>
    <PButton
        v-if="isLocal"
        icon="refresh"
        label="Update theme"
        size="small"
        @click="updatePreset"
    />
</template>
